import { t } from 'i18next';
import moment from 'moment';
import React, {useState} from 'react';
import { useForm } from 'react-hook-form';

import { EventTileCompact } from './EventTileCompact';
import { CommonProps } from './interfaces';
import { useEvents } from './useEvents';
import { useMediaQuery } from './useMediaQuery';
import { formatDateForInput } from './utils';
import DatePicker from "react-datepicker";

export interface ReservationProps extends CommonProps {
  currentRoomCode?: string;
  hotelCode?: string;
  propertyId?: string;
  reservationTunnelStartURL?: string;
  showEvents?: boolean;
  template?: 'header' | 'mobile' | 'desktop';
}

type Inputs = {
  endDate: string;
  guestsNum: number;
  roomsNum: number;
  startDate: string;
};

export const Reservation = ({
  contextPath,
  currentRoomCode,
  hotelCode,
  propertyId,
  reservationTunnelStartURL,
  showEvents,
  template,
  whatsOnCalendarURL,
  whatsOnInTheCityLong,
  language,
}: ReservationProps) => {
  if (contextPath === undefined) {
    contextPath = '';
  }

  if (propertyId === undefined) {
    throw new Error('propertyId undefined');
  }

  const desktop = useMediaQuery('(min-width: 992px)');

  const { register, setValue, watch } = useForm<Inputs>({
    defaultValues: {
      endDate: formatDateForInput(moment().add(1, 'day').toDate()),
      startDate: formatDateForInput(new Date()),
    },
  });

const startDate = watch('startDate');
const endDate = watch('endDate');
// const guestsNum = watch('guestsNum');
// const roomsNum = watch('roomsNum');
const [startDateSelection, setStartDateSelection] = useState<Date | null>(moment().toDate());
const [endDateSelection, setEndDateSelection] = useState<Date | null>(moment().add(1, 'day').toDate());

const { events } = useEvents({ active: showEvents ?? false, contextPath, endDate, limit: 3, propertyId, startDate });
const onStartDateChange = (date: Date | null) => {
    setStartDateSelection(date);
    if (date) {
        const nextDay = new Date(date);
        nextDay.setDate(nextDay.getDate() + 1);
        // Check if endDate is before the next day
        if (endDateSelection && endDateSelection < nextDay) {
            setEndDateSelection(nextDay);
        }
    }
};

  // console.log('Reservation', { endDate, events, guestsNum, roomsNum, startDate });

  const handleInputDateClick = React.useCallback(() => {
    setTimeout(() => {
      const datePickerDays = document.querySelectorAll(
        '.ui-datepicker table.ui-datepicker-calendar > tbody > tr > td > a'
      );
      datePickerDays.forEach((datePickerDay) => {
        datePickerDay.addEventListener('click', () => {
          setTimeout(() => {
            setValue(
              'startDate',
              document.querySelector<HTMLInputElement>(`[data-react-component="Reservation"] #startDate-${template}`)
                ?.value ?? ''
            );
            setValue(
              'endDate',
              document.querySelector<HTMLInputElement>(`[data-react-component="Reservation"] #endDate-${template}`)
                ?.value ?? ''
            );
          }, 100);
        });
      });
    }, 100);
    // }
  }, [setValue, template]);

  const handleCalendarIconButton = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent> | React.KeyboardEvent<HTMLDivElement>) => {
      if (event.target instanceof HTMLElement) {
        const element = event.target;
        const inputGroupElement = element.closest('.input-group');
        const inputElement = inputGroupElement?.firstElementChild;
        console.log('handleCalendarIconButton', element, inputGroupElement, inputElement);
        if (inputElement instanceof HTMLElement) {
          inputElement.click();
        }
      }
    },
    []
  );

  const onEndDateChange = (date: Date | null) => {
    setEndDateSelection(date);
    if (date && startDateSelection && date < startDateSelection) {
      const newStartDate = moment(date).subtract(1, 'day').toDate();
      setStartDateSelection(newStartDate);
      setValue('startDate', formatDateForInput(newStartDate));
    }
  };


  function renderDateInput(inputType: 'startDate' | 'endDate')  {
    const dateId = `${inputType}-${template}`;

    if (template === 'mobile') {
      return (
          <div className="input-group input-append date">
            <input
                autoComplete="off"
                className="form-control two-months"
                id={dateId}
                onClick={handleInputDateClick}
                type="date"
                {...register(inputType)}
            />
            <div
                className="input-group-addon btn"
                onClick={handleCalendarIconButton}
                onKeyDown={handleCalendarIconButton}
                role="button"
                tabIndex={0}
            >
              <span className="bhricon bhricon-calendar"></span>
            </div>
          </div>
      );
    } else {
      const handleChange = inputType === 'startDate' ? onStartDateChange : onEndDateChange;
      const selectedDate = inputType === 'startDate' ? startDateSelection : endDateSelection;
      const placement = template === "header" ? 'bottom-end' : undefined;

      return (
          <DatePicker
              showIcon
              icon="bhricon bhricon-calendar"
              locale={language}
              onChange={handleChange}
              selected={selectedDate}
              className="form-control"
              dateFormat="yyyy/MM/dd"
              monthsShown={2}
              name={dateId}
              id={dateId}
              minDate={new Date()}
              showPopperArrow={false}
              popperPlacement={placement}
              popperProps={{ strategy: 'fixed' }}
          />
      );
    }
  }


  return (
    <>
      <form
        action={reservationTunnelStartURL}
        autoComplete="off"
        className=""
        data-form-id="room-reservation-tile"
        method="GET"
      >
        <div className="form-wrapper form-horizontal">
          <div className="row">
            <div className="col-sm-12">
              <div className="control-group">
                <label className="control-label" htmlFor={`startDate-${template}`}>
                  {t('arrivalDate')}
                </label>
                <div className="controls">
                  {renderDateInput('startDate')}
                </div>
              </div>
            </div>
            <div className="col-sm-12">
              <div className="control-group">
                <div className="controls">
                  <label className="control-label" htmlFor={`endDate-${template}`}>
                    {t('departureDate')}
                  </label>
                  {renderDateInput('endDate')}
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="control-group">
                <label className="control-label" htmlFor={`guestsNum-${template}`}>
                  {t('selectedPersons')}
                </label>
                <div className="form-select">
                  <select className="form-control" {...register('guestsNum')}>
                    {[...Array(10)].map((_item, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <option key={index}>{index + 1}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="control-group">
                <label className="control-label" htmlFor={`roomsNum-${template}`}>
                  {t('selectedRooms')}
                </label>
                <div className="form-select">
                  <select className="form-control" {...register('roomsNum')}>
                    {[...Array(3)].map((_item, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <option key={index}>{index + 1}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="col-sm-12">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              {/* <a className="btn btn-primary btn-reservation text-uppercase text-center flip pull-right" href="#">
                <span className="text">findRates</span>
              </a> */}
              <button className="btn btn-primary btn-reservation text-uppercase text-center flip pull-right">
                {t('findRates')}
              </button>
            </div>
            <input name="propertyId" type="hidden" value={hotelCode} />
            {currentRoomCode && (
              <input id={`modal-reserve-room-id-${template}`} name="roomId" type="hidden" value={currentRoomCode} />
            )}
            <input name="startDate" type="hidden" value={startDateSelection ? startDateSelection.toISOString() : ''}/>
            <input name="endDate" type="hidden" value={endDateSelection ? endDateSelection.toISOString() : ''}/>
          </div>
        </div>
      </form>
      {showEvents && events && (
        <div
          style={{
            backgroundColor: '#ECE8E2',
            marginBottom: 20,
            paddingBottom: 20,
            position: 'relative',
            ...(desktop
              ? {
                  marginBottom: 0,
                  marginLeft: -20,
                  marginRight: -20,
                  marginTop: -20,
                  top: 20,
                }
              : undefined),
          }}
        >
          <h5 className="text-center text-uppercase" style={{ padding: '10px 20px 0px 20px' }}>
            {whatsOnInTheCityLong}
          </h5>
          <hr style={{ borderTopWidth: 2, margin: '10px 20px' }} />
          {events.map((event) => (
            <>
              <EventTileCompact event={event} key={event.id} />
              <hr style={{ borderTopWidth: 2, margin: '10px 20px' }} />
            </>
          ))}
          <div className="text-center" style={{ padding: '10px 20px 0px 20px' }}>
            <a className="btn btn-default" href={whatsOnCalendarURL} style={{ marginTop: 0, padding: '0 20px' }}>
              {t('viewCalendar')}
            </a>
          </div>
        </div>
      )}
    </>
  );
};
