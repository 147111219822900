import { en } from './en';

export const id: Partial<typeof en> = {
  arrivalDate: 'Kedatangan',
  departureDate: 'Keberangkatan',
  findRates: 'Temukan tarif',
  readMore: 'Baca selengkapnya',
  selectedPersons: 'Orang/Kamar',
  selectedRooms: 'Kamar',
};
