import { en } from './en';

export const zh_TW: Partial<typeof en> = {
  arrivalDate: '抵達',
  departureDate: '出發',
  findRates: '查找房價',
  readMore: '閱讀更多',
  selectedPersons: '人/間',
  selectedRooms: '房間數',
};
