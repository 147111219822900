import axios, { isAxiosError } from 'axios';
import React from 'react';

import { EventApiDto } from './interfaces';
import { parseDateFromInput } from './utils';

export interface UseEventsProps {
  active: boolean;
  contextPath: string;
  endDate: string;
  limit?: number;
  propertyId: string;
  startDate: string;
}

export interface UseEventsResult {
  events?: Array<EventApiDto>;
  fetching: boolean;
}

// export const useEvents = (startDate?: string, endDate?: string, propertyId?: string): UseEventsResult => {
export const useEvents = ({
  active,
  contextPath,
  endDate,
  limit,
  propertyId,
  startDate,
}: UseEventsProps): UseEventsResult => {
  const [events, setEvents] = React.useState<Array<EventApiDto>>();
  const [fetching, setFetching] = React.useState<boolean>(false);

  React.useEffect(() => {
    // const isValidStartDate = isValidDate(parseDateFromInput(startDate));
    // const isValidEndDate = isValidDate(parseDateFromInput(endDate));

    // console.log('useEvents', { endDate, isValidEndDate, isValidStartDate, propertyId, startDate });

    // if (isValidStartDate && isValidEndDate) {
    // console.log('Faccio la chiamate alle API con questi valori:', {
    //   endDate,
    //   startDate,
    // });
    if (active) {
      setFetching(true);
      axios
        .get<Array<EventApiDto>>(`${contextPath}/.rest/events/${propertyId}`, {
          params: {
            // limit: limit,
          },
        })
        .then((response) => {
          setEvents(response.data);
        })
        .catch((error) => {
          if (isAxiosError(error)) {
          } else {
          }
        })
        .finally(() => {
          setFetching(false);
        });
    }
    // }
  }, [active, contextPath, limit, propertyId]);

  const filteredEvents = React.useMemo(() => {
    console.log('useMemo', { endDate, startDate });
    const startDateAsMillis = parseDateFromInput(startDate)?.getTime();
    const endDateAsMillis = parseDateFromInput(endDate)?.getTime();

    let filteredEventsByDates: Array<EventApiDto> | undefined;

    if (startDateAsMillis !== undefined && endDateAsMillis !== undefined) {
      filteredEventsByDates = events?.filter((event) => {
        if (!event.startDate || !event.endDate) {
          return false;
        } else {
          return event.endDate >= startDateAsMillis && event.startDate <= endDateAsMillis;
        }
      });
    } else {
      filteredEventsByDates = events;
    }

    return filteredEventsByDates?.slice(0, limit);
  }, [endDate, events, limit, startDate]);

  return { events: filteredEvents, fetching };
};
