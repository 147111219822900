import { en } from './en';

export const ru: Partial<typeof en> = {
  arrivalDate: 'Прибытие',
  departureDate: 'Дата выезда',
  findRates: 'Найти цены',
  readMore: 'Читать дальше',
  selectedPersons: 'Число гостей',
  selectedRooms: 'Кол-во номеров',
};
