import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { ar_SA } from './locales/ar_SA';
import { en } from './locales/en';
import { fr } from './locales/fr';
import { id } from './locales/id';
import { it } from './locales/it';
import { ja } from './locales/ja';
import { ko } from './locales/ko';
import { ru } from './locales/ru';
import { zh_CN } from './locales/zh_CN';
import { zh_TW } from './locales/zh_TW';

export const i18nInit = (lng?: string) => {
  // eslint-disable-next-line import/no-named-as-default-member
  i18n.use(initReactI18next).init({
    fallbackLng: 'en_US',
    interpolation: {
      escapeValue: false,
    },
    lng: lng,
    resources: {
      ar_SA: { translation: ar_SA },
      en_US: { translation: en },
      fr_FR: { translation: fr },
      in_ID: { translation: id },
      it_IT: { translation: it },
      ja_JP: { translation: ja },
      ko_KR: { translation: ko },
      ru_RU: { translation: ru },
      zh_CN: { translation: zh_CN },
      zh_TW: { translation: zh_TW },
    },
  });
};
