import moment from 'moment';

import { useMediaQuery } from './useMediaQuery';

export const getReactProps = <T extends object>(element: HTMLElement): T | undefined => {
  const reactPropsString = element.dataset['reactProps'];
  const reactProps: Record<string, unknown> = reactPropsString !== undefined ? JSON.parse(reactPropsString) : {};
  const fixedReactProps = Object.entries(reactProps).reduce<Record<string, unknown>>((acc, [key, value]) => {
    acc[key] = value !== '' ? value : undefined;
    return acc;
  }, {});
  return fixedReactProps as T | undefined;
};

export const formatDate = (date: Date, format = 'L') => moment(date).format(format);
export const parseDate = (date: string, format: string): Date => moment(date, format).toDate();

export const formatDateForInput = (date?: Date) => (date ? formatDate(date, 'YYYY-MM-DD') : '');
export const parseDateFromInput = (date?: string): Date | undefined =>
  date ? parseDate(date, 'YYYY-MM-DD') : undefined;

export const formatDateTimeForInput = (date?: Date) => (date ? formatDate(date, 'YYYY-MM-DDTHH:mm') : '');
export const parseDateTimeFromInput = (date?: string): Date | undefined =>
  date ? parseDate(date, 'YYYY-MM-DDTHH:mm') : undefined;

export const isValidDate = (date?: Date) => date !== undefined && !isNaN(date.getTime());

export const formatDateFromMillis = (millis: number) => formatDate(new Date(millis));

export const formatHtml = (html?: string) => (
  <>{html ? <div dangerouslySetInnerHTML={{ __html: html }}></div> : <></>}</>
);

export const useMainMediaQuery = () => {
  const desktop = useMediaQuery('(min-width: 769px)');
  return {
    desktop: desktop,
    mobile: !desktop,
  };
};
