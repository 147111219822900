import 'moment/dist/locale/ar-sa';
import 'moment/dist/locale/fr';
import 'moment/dist/locale/id';
import 'moment/dist/locale/it';
import 'moment/dist/locale/ja';
import 'moment/dist/locale/ko';
import 'moment/dist/locale/ru';
import 'moment/dist/locale/zh-cn';
import 'moment/dist/locale/zh-tw';
import 'vite/modulepreload-polyfill';
import './style.scss';

import ar_SA from 'date-fns/locale/ar-SA';
import en_US from 'date-fns/locale/en-US';
import fr from 'date-fns/locale/fr';
import id from 'date-fns/locale/id';
import it from 'date-fns/locale/it';
import ja from 'date-fns/locale/ja';
import ko from 'date-fns/locale/ko';
import ru from 'date-fns/locale/ru';
import zh_CN from 'date-fns/locale/zh-CN';
import zh_TW from 'date-fns/locale/zh-TW';
import moment from 'moment';
import React from 'react';
import { registerLocale as registerDatepickerLocale } from 'react-datepicker';
import ReactDOM from 'react-dom/client';

import { Datepicker } from './Datepicker';
import { Calendar } from './Calendar';
import { i18nInit } from './i18n.ts';
import { CommonProps } from './interfaces';
import { Reservation } from './Reservation';
import { getReactProps } from './utils';

registerDatepickerLocale('ar_SA', ar_SA);
registerDatepickerLocale('en_US', en_US);
registerDatepickerLocale('fr_FR', fr);
registerDatepickerLocale('in_ID', id);
registerDatepickerLocale('it_IT', it);
registerDatepickerLocale('ja_JP', ja);
registerDatepickerLocale('ko_KR', ko);
registerDatepickerLocale('ru_RU', ru);
registerDatepickerLocale('zh_CN', zh_CN);
registerDatepickerLocale('zh_TW', zh_TW);

const commonReactData = getReactProps<CommonProps>(document.body);

moment.locale(commonReactData?.language);
i18nInit(commonReactData?.language);

const components: Record<string, React.FunctionComponent> = {
  Calendar,
  Reservation,
  Datepicker,
};

const componentsWrappers = document.querySelectorAll('[data-react-component]');
Array.from(componentsWrappers).forEach((componentWrapper) => {
  if (componentWrapper instanceof HTMLElement) {
    const component = componentWrapper.dataset.reactComponent;
    if (component) {
      const Component = components[component];
      const props = { ...getReactProps(document.body), ...getReactProps(componentWrapper) };
      ReactDOM.createRoot(componentWrapper).render(
        <React.StrictMode>
          <Component {...props} />
        </React.StrictMode>
      );
    }
  }
});
