import { en } from './en';

export const zh_CN: Partial<typeof en> = {
  arrivalDate: '抵达',
  departureDate: '启程',
  findRates: '查找价格',
  readMore: '阅读更多',
  selectedPersons: '人数/客房',
  selectedRooms: '房间',
};
