import React from 'react';

const matchMedia = (query: string) => typeof window !== 'undefined' && window.matchMedia(query);

export const useMediaQuery = (query: string) => {
  const [matches, setMatches] = React.useState<boolean>(() => {
    const mql = matchMedia(query);
    if (!mql) {
      return false;
    }
    return mql.matches;
  });

  React.useEffect(() => {
    const mediaQueryList = matchMedia(query);
    const handleChange = (event: MediaQueryListEvent) => {
      setMatches(event.matches);
    };
    if (!mediaQueryList) {
      return;
    }
    mediaQueryList.addEventListener('change', handleChange);
    return () => {
      mediaQueryList.removeEventListener('change', handleChange);
    };
  }, [query]);

  return matches;
};
