import { en } from './en';

export const ko: Partial<typeof en> = {
  arrivalDate: '도착',
  departureDate: '출발',
  findRates: '숙박료 찾아보기',
  readMore: '자세한 정보',
  selectedPersons: '개인/객실',
  selectedRooms: '객실',
};
