import { EventTileDescription } from './EventTile';
import { EventApiDto } from './interfaces';

export interface EventTileCompactProps {
  event: EventApiDto;
}

export const EventTileCompact = ({ event }: EventTileCompactProps) => {
  return (
    <div className="row" style={{ margin: 0 }}>
      <div className="col-xs-4" style={{ padding: '0 20px' }}>
        {event.photos && event.photos.length > 0 && (
          <a href={event.url}>
            <img alt={event.title} className="img-responsive" src={event.photos[0].SQUARE} />
          </a>
        )}
      </div>
      <div className="col-xs-8" style={{ padding: '0 20px', paddingLeft: 0 }}>
        <EventTileDescription compact event={event} />
      </div>
    </div>
  );
};
