// import { getConstants } from '.';

export interface LoaderProps {
  src?: string;
}

export const Loader = ({ src }: LoaderProps) => {
  return (
    <div style={{ margin: '10px auto', width: 50 }}>
      {src ? <img alt="loading..." className="img-responsive" src={src} /> : <span>Loading....</span>}
    </div>
  );
};
