import { en } from './en';

export const it: Partial<typeof en> = {
  arrivalDate: 'Arrivo',
  departureDate: 'Partenza',
  findRates: 'Verifica disponibilità',
  readMore: 'Continua a leggere',
  selectedPersons: 'Persone/Camera',
  selectedRooms: 'Camere',
};
