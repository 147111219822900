import { en } from './en';

export const ja: Partial<typeof en> = {
  arrivalDate: 'ご到着日',
  departureDate: 'ご出発日',
  findRates: '料金を検索',
  readMore: 'もっと読む',
  selectedPersons: '人数/部屋',
  selectedRooms: '部屋数',
};
