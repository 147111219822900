import moment from 'moment';
import React from 'react';
import DatePicker from 'react-datepicker';

import { EventTile } from './EventTile';
import { CommonProps } from './interfaces';
import { Loader } from './Loader';
import { useEvents } from './useEvents';
import { formatDateForInput, useMainMediaQuery } from './utils';

export interface CalendarProps extends CommonProps {
  propertyId?: string;
}

export const Calendar = ({ contextPath, language, loaderImageSrc, propertyId }: CalendarProps) => {
  if (contextPath === undefined) {
    contextPath = '';
  }

  if (propertyId === undefined) {
    throw new Error('propertyId undefined');
  }

  const { mobile } = useMainMediaQuery();

  const [startDate, setStartDate] = React.useState<Date | undefined>(moment().toDate());
  const [endDate, setEndDate] = React.useState<Date | undefined>(moment().add(7, 'days').toDate());

  const { events, fetching } = useEvents({
    active: true,
    contextPath: contextPath,
    endDate: endDate ? formatDateForInput(endDate) : '',
    propertyId: propertyId,
    startDate: startDate ? formatDateForInput(startDate) : '',
  });

  const onChange = React.useCallback((dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start ?? undefined);
    setEndDate(end ?? undefined);
  }, []);

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-4 col-sm-push-8">
          <div style={{ marginBottom: 30, textAlign: mobile ? 'center' : 'end', display: "inline-block !important" }}>
            <DatePicker
              endDate={endDate}
              inline
              locale={language}
              onChange={onChange}
              selected={startDate}
              selectsRange
              startDate={startDate}
            />
          </div>
        </div>
        <div className="col-sm-8 col-sm-pull-4">
          {fetching ? (
            <Loader src={loaderImageSrc} />
          ) : (
            <div style={{ marginBottom: 30 }}>
              {events?.map((event) => (
                <EventTile event={event} key={event.id} />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
