import moment from 'moment';
import React from 'react';
import DatePicker from 'react-datepicker';
import { CommonProps } from './interfaces';


export interface DatepickerProps extends CommonProps {
  propertyId?: string;
  template?: string;
  name?: string;
}

export const Datepicker = ({ contextPath, language, propertyId, template, name}: DatepickerProps) => {
  if (contextPath === undefined) {
    contextPath = '';
  }

  if (propertyId === undefined) {
    throw new Error('propertyId undefined');
  }

  if (template === undefined) {
    template = 'desktop';
  }

  if (name === undefined) {
    name = '';
  }

  const [selectedDate, setSelectedDate] = React.useState<Date | null>(moment().toDate());

  const onChange = (date: Date | null) => {
    setSelectedDate(date);
  };

  return (
        <DatePicker
            showIcon
            icon="bhricon bhricon-calendar"
            locale={language}
            onChange={onChange}
            selected={selectedDate}
            className="form-control"
            dateFormat="yyyy/MM/dd"
            minDate={new Date()}
            name={name}
            showPopperArrow={false}
        />
    );
};
