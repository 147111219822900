import { en } from './en';

export const ar_SA: Partial<typeof en> = {
  arrivalDate: 'الوصول',
  departureDate: 'المغادرة',
  findRates: 'إيجاد أسعار',
  readMore: 'إقرأ المزيد',
  selectedPersons: 'أشخاص/غرفة',
  selectedRooms: 'الغرف',
};
