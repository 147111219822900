export const en = {
  arrivalDate: 'Arrival',
  departureDate: 'Departure',
  findRates: 'Find rates',
  readLess: 'Read less',
  readMore: 'Read more',
  selectedPersons: 'Persons/Room',
  selectedRooms: 'Rooms',
  viewCalendar: 'View calendar',
};
