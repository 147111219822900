import classnames from 'classnames';
import { t } from 'i18next';
import React from 'react';

import { Icon } from './Icon';
import { EventApiDto } from './interfaces';
import { formatDateFromMillis, formatHtml, useMainMediaQuery } from './utils';

export interface EventTileProps {
  event: EventApiDto;
}

export const EventTile = ({ event }: EventTileProps) => {
  const { desktop } = useMainMediaQuery();

  return (
    <>
      <div style={{ backgroundColor: '#ECE8E2', display: desktop ? 'flex' : 'block', marginBottom: 20 }}>
        <a
          href={event.url}
          style={
            desktop
              ? {
                  backgroundImage:
                    event.photos && event.photos.length > 0 ? `url(${event.photos[0].LANDSCAPE})` : undefined,
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  flex: 1,
                }
              : {}
          }
        >
          {event.photos && event.photos.length > 0 && (
            <img
              alt={event.title}
              className="img-responsive"
              src={event.photos[0].LANDSCAPE}
              style={{ display: desktop ? 'none' : 'block' }}
            />
          )}
        </a>
        <div style={{ flex: desktop ? 1 : undefined, padding: 20 }}>
          <EventTileDescription event={event} />
        </div>
      </div>
    </>
  );
};

export interface EventTileDescriptionProps extends EventTileProps {
  compact?: boolean;
}

export const EventTileDescription = ({
  compact,
  event: { endDate, location, startDate, summary, title, url },
}: EventTileDescriptionProps) => {
  const [expanded, setExpanded] = React.useState<boolean>(false);

  const toggle = React.useCallback(() => {
    setExpanded((prevState) => !prevState);
  }, []);

  const marginBottom = compact ? 5 : 10;

  return (
    <>
      <h5 className={classnames({ small: compact })} style={{ marginBottom: marginBottom + 5 }}>
        <a href={url}>{title}</a>
      </h5>
      <dl className="dl-horizontal" style={{ marginBottom: marginBottom }}>
        <dt style={{ float: 'left', width: 15 }}>
          <Icon name="pin" />
        </dt>
        <dd className="small" style={{ marginBottom: compact ? undefined : 10, marginLeft: 20 }}>
          {location}
        </dd>
        {startDate && endDate && (
          <>
            <dt style={{ float: 'left', width: 15 }}>
              <Icon name="calendar" />
            </dt>
            <dd className="small" style={{ fontSize: 11, lineHeight: '18px', marginLeft: 20 }}>
              {formatDateFromMillis(startDate)} - {formatDateFromMillis(endDate)}
            </dd>
          </>
        )}
      </dl>
      {compact ? (
        <>
          {expanded && (
            <div className="small" style={{ marginBottom: marginBottom }}>
              {formatHtml(summary)}
            </div>
          )}
          <div
            className="small text-uppercase-button"
            onClick={toggle}
            onKeyDown={toggle}
            role="button"
            style={{ cursor: 'pointer' }}
            tabIndex={0}
          >
            {expanded ? t('readLess') : t('readMore')}
          </div>
        </>
      ) : (
        <a className="small text-uppercase" href={url}>
          {t('readMore')}
        </a>
      )}
    </>
  );
};
