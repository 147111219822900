import { en } from './en';

export const fr: Partial<typeof en> = {
  arrivalDate: 'Arrivée',
  departureDate: 'Départ',
  findRates: 'Réserver',
  readMore: 'En savoir plus',
  selectedPersons: 'Personnes/Chambres',
  selectedRooms: 'Chambres',
};
